import { createSlice } from "@reduxjs/toolkit";

const taskSlice = createSlice({
    name: "task",
    initialState: { task_count: 0, task_list: [] },
    reducers: {
        incrTaskCount(state, action) {
            state.task_count += action.payload;
        },
        addTaskList(state, action) {
            state.task_list = action.payload;
        }
    },
});

export const { incrTaskCount, addTaskList } = taskSlice.actions;
export default taskSlice.reducer;
