import React, { useState } from "react";
import {
  ProjectListWrapper,
  ProjectListOptionsWrapper,
  ProjectListTitle,
  ProjectListTitleIcon
} from "./ProjectList.styled";
import Search from "../../../components/Search/Search";
import Button from "../../../components/Button/Button";
import ModalWrapper from "../../../components/ModalWrapper/ModalWrapper";
import AddProjectModal from "../AddProjectModal/AddProjectModal";
import ProjectListTable from "../ProjectListTable/ProjectListTable";
import SuccessPopup from "../../../components/SuccesPopup/SuccessPopup";
import useKeyPress from "../../../hooks/useKeyPress";
import speechFormat from "../../../helpers/speechFormat";
import TitleComponent from "../../../components/TitleComponent/TitleComponent";

const ProjectList = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [input, setInput] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [loadingSuccessPopup, setLoadingSuccessPopup] = useState(false);

  const closeModal = () => {
    setModalOpen(false);
  };

  const fetchInput = (val) => {
    setInput(val);
  };

  const handleSuccess = ({ loading }) => {
    setShowSuccessPopup(true);
    setLoadingSuccessPopup(loading);
    closeModal();
  };

  const overlayName = "AddProjectModal";
  useKeyPress("P", ["altKey"], () => setModalOpen(true));
  useKeyPress("Escape", [], closeModal, true, overlayName);

  return (
    <>
      <ProjectListWrapper>
        <div style={{ display: "flex" }}>
          <ProjectListTitleIcon>Regulatory PM</ProjectListTitleIcon>
          <ProjectListTitle></ProjectListTitle>
          <TitleComponent />
        </div>
        <ProjectListOptionsWrapper>
          <Search
            width={400}
            thres={1300}
            placeholdertext={"Search Projects..."}
            righticons={true}
            onChange={fetchInput}
            value={input}
            searchCommand={{
              text: "[G]",
              key: "G",
              subkey: [],
            }}
            voiceCommand={{
              text: "[Alt+G]",
              key: "G",
              subkey: ["altKey"],
            }}
            speech={speechFormat({
              action: "search",
              item: "projects",
            })}
          />
          <Button
            type="brand"
            text="+ Add a New Project Timeline"
            accessKeyText="[Alt + P]"
            onClick={() => setModalOpen(true)}
            buttonStyle={{ marginLeft: 10 }}
            data-speech={speechFormat({
              key: "Alt + P",
              action: "",
              item: "Add a New Project Timeline",
            })}
          />
        </ProjectListOptionsWrapper>
      </ProjectListWrapper>
      {modalOpen && (
        <ModalWrapper
          isOpen={modalOpen}
          onClose={closeModal}
          overlayName={overlayName}
        >
          <AddProjectModal
            onClose={closeModal}
            onSuccess={handleSuccess}
            overlayName={overlayName}
          />
        </ModalWrapper>
      )}
      
      <ProjectListTable searchInput={input} setSearchInput={setInput} />
      {showSuccessPopup && (
        <SuccessPopup
          msg_processing={"Adding a new project, please wait..."}
          msg_done={"Project is added successfully."}
          onClose={() => setShowSuccessPopup(false)}
          loading={loadingSuccessPopup}
        />
      )}
    </>
  );
};

ProjectList.propTypes = {};

ProjectList.defaultProps = {};

export default ProjectList;
