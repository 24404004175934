import React from 'react'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import {AutocompleteWrapper} from "./Autocomplete.styled";

function Autocomplete({items, placeholder, onSelected}) {

  const handleOnSelect = (item) => {
    onSelected(item["name"])
  }

  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
      </>
    )
  }

  return (
    <div>
        <AutocompleteWrapper style={{ width: 584 }} >
          <ReactSearchAutocomplete
            className='headerSearch'
            items={items}
            onSelect={handleOnSelect}
            autoFocus
            placeholder={placeholder}
            formatResult={formatResult}
            maxResults={3}
            showIcon={false}
          />
        </AutocompleteWrapper>
    </div>
  )
}

export default Autocomplete