import { useEffect, useState } from "react";
import { ProgressBarContainer, ProgressInfoWrapper, ProgressInfo, ProgressTimeWrapper } from "./ProgressBar.styled";

const ProgressBar = ({ progress, width="initial" }) => {
    const [value, setValue] = useState(0)
    useEffect(() => {
        if (progress) {
            const percentage = Math.floor((progress.mappings_processed / progress.total_mappings) * 100)
            setValue(percentage || 0)
        }
    }, [progress])
    return (
        <>
            <ProgressBarContainer progress={value} style={{width: width}}>
                <div className="progress-inner"></div>
            </ProgressBarContainer>
            {progress?.estimated_time ? <ProgressInfoWrapper>
                <ProgressInfo>{`Mapping in progress: ${value}% - ${progress?.mappings_processed} of ${progress?.total_mappings}`}</ProgressInfo>
                <ProgressTimeWrapper>{`${progress?.estimated_time} mins remaining`}</ProgressTimeWrapper>
            </ProgressInfoWrapper>
            :     
            <ProgressInfo>{`Task completed: ${value}% `}</ProgressInfo>
        }
        </>
    )
}

export default ProgressBar;