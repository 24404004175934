import styled from "styled-components";
import Fonts from "../../../Fonts/Fonts";

export const ModalWrapper = styled.div`
  display: flex;
  width: 956px;
  height: 520px;
  flex-direction: column;
  align-items: flex-start;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 12px;
  margin-bottom: 20px;
`;

export const HeadTitle = styled.div`
  ${Fonts.Body3}
  text-transform: uppercase;
`;

export const Title = styled.h5`
  text-transform: uppercase;
`;
export const HeadRight = styled.div`
  display: flex;
`;


export const ExtraWrapper = styled.div`
  width: 100%;
  padding-top: 15px;
`

export const ErrorWrapper = styled.div`
  padding: 10px;
  color: red;
  font-size: ${(props) => props.theme.font_delta + 10}px;
`