import {StyledDatePicker} from "./DateSelector.styled"
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';


const DateSelector = ({value, setValue, clearIcon=false, type="button", width="584px"}) => {
    return (
        <div>
            <StyledDatePicker 
                value={value}
                type={type}
                width={width} 
                clearIcon={clearIcon}
                onChange={setValue} 
                minDate={new Date()}
            />
        </div>
    )
}


export default DateSelector;

