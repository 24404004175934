import { createSlice } from "@reduxjs/toolkit";

const projectSlice = createSlice({
    name: "project",
    initialState: { project_count: 0, project_list: [] },
    reducers: {
        incrProjectCount(state, action) {
            state.project_count += action.payload;
        },
        addProjectList(state, action) {
            state.project_list = action.payload;
        }
    },
});

export const { incrProjectCount, addProjectList } = projectSlice.actions;
export default projectSlice.reducer;
