import styled from "styled-components";



export const AutocompleteWrapper = styled.div`

    .selected {
        color: ${(props) => props.theme.color_theme.color};

        &:hover {
            background-color: #2E9866;
            color: ${(props) => props.theme.color_theme.color}
        }
    }

    .wrapper {
       border-radius: 18px;
    }

    .headerSearch .wrapper ul {
        z-index: 9999 !important;
        color: ${(props) => props.theme.color_theme.color};
        background: ${(props) => props.theme.color_theme.inputFillColor};
    }

    .headerSearch .wrapper ul li {
        background: ${(props) => props.theme.color_theme.inputFillColor};
        &:hover {
            background-color: #2E9866;
            color: ${(props) => props.theme.color_theme.color}
        }
    }

    .headerSearch .wrapper div input {
        color: ${(props) => props.theme.color_theme.color};
    }

    .wrapper:focus-within {
        box-shadow: none !important;
    }

    .wrapper:first-child {
        height: 48px;
        font-size: ${(props) => props.theme.font_delta + 13}px;
        font-style: normal;
        font-weight: 400;
        background-position: 10px center;
        background-repeat: no-repeat;
        background-size: 20px;
        border-radius: 18px;
        pading-top: 4px;
        border: ${(props) => `1px solid ${props.theme.color_theme.inputBorderColor}`};
        background: ${(props) => props.theme.color_theme.inputFillColor};
        color: ${(props) => props.theme.color_theme.color};
        box-shadow: none;
        &:focus {
            border-color: ${(props) => props.theme.color_theme.primaryColor};
            outline: none;
            box-shadow: none;
        }
        &:hover {
            box-shadow: none;
        }
    }
`;