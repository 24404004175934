import React, {
    useEffect,
    useState,
    useRef
} from 'react';
import { StyledReactSearchAutocomplete, StyledSearchInput, StyledResults, IconWrapper, RightIconWrapper, ExtraTextWrapper } from './AutoCompleteSearch.styled';
import { getEntitlement, searchUser } from '../../apis/ApiServerRequests';
import validateEmail from '../../helpers/validateEmail';
import SearchIcon from '../../assets/svgs/SearchIcon';
import AddUserIcon from '../../assets/svgs/AddUserIcon';
import useKeyPress from '../../hooks/useKeyPress';
import DownIcon from "../../assets/svgs/DownIcon";


const SearchAutocomplete = ({ handleAddMember, placeholder, searchCommand, isOverlay,
    overlayName, rightIcon, dropDown, autoFocus = false, searchEnabled = true, enableAddButton=true, icon=<AddUserIcon /> }) => {

    const [searchString, setSearchString] = useState('');
    const [highlightedItem, setHighlightedItem] = useState(-1);
    const [results, setResults] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false)

    const ref = useRef(null);
    const maxResults = searchEnabled ? 3 : 20;

    const handleSearchString = (e) => {
        if (searchEnabled) setSearchString(e.target.value)
    }

    const handleSelectedItem = (event, index = undefined) => {
        let itemIndex = -1
        if (index !== undefined) {
            setHighlightedItem(index);
            if (searchEnabled) {
                handleAddMember(results[index])
                setResults([]);
            } else {
                handleAddMember(results[index].name)
            }
            setSearchString('');
            setShowDropdown(false);
        } else if (event) {
            switch (event.key) {
                case 'Enter':
                    if (results.length > 0 && results[highlightedItem]) {
                        event.preventDefault()
                        if (searchEnabled) {
                            handleAddMember(results[highlightedItem])
                            setResults([]);
                        } else {
                            handleAddMember(results[highlightedItem].name)
                            setShowDropdown(false);
                        }
                        setSearchString('');
                    }
                    setHighlightedItem(-1)
                    break
                case 'ArrowUp':
                    event.preventDefault()
                    itemIndex = highlightedItem > -1 ? highlightedItem - 1 : results.length - 1
                    setHighlightedItem(itemIndex);
                    break
                case 'ArrowDown':
                    event.preventDefault()
                    itemIndex = highlightedItem < results.length - 1 ? highlightedItem + 1 : -1
                    setHighlightedItem(itemIndex);
                    break
                default:
                    break
            }
        }
    }

    const handleAddUser = () => {
        if (searchEnabled) {
            handleEscSearch()
            if (validateEmail(searchString)) {
                handleAddMember({ email: searchString })
            } else {
                handleAddMember({ name: searchString.charAt(0).toUpperCase() + searchString.slice(1) })
            }
        } else {
            handleAddMember(searchString)
        }
        setHighlightedItem(null);
        setSearchString('');
        setResults([])
    }

    function highlightMatchingChars(string1, string2) {
        string1 = string1.toLowerCase();
        string2 = string2.toLowerCase();
        if (string1 && string2.includes(string1)) {
            const result = [];
            const maxLength = string2.length;

            for (let i = 0; i < maxLength; i++) {
                const char1 = string1[i];
                const char2 = string2[i];

                if (char1 === char2) {
                    if (i === 0) {
                        result.push(<strong key={i}>{char1.toUpperCase()}</strong>);
                    } else {
                        result.push(<strong key={i}>{char1.toLowerCase()}</strong>);
                    }
                } else {
                    result.push(char1);
                    result.push(char2);
                }
            }
            return result;
        }
    }


    useEffect(() => {
        if (searchString.length > 4 && searchEnabled) {
            searchUser(searchString).then(({ data }) => setResults(data))
        } else {
            if (!searchEnabled) getEntitlement().then(({data}) => {
                setResults(data.entitlements.map(item => ({name: item, email: ''})))
            })
        }
    }, [searchString])

    const handleSearch = () => {
        ref?.current?.focus(); // Focus on the input field
    };

    const handleEscSearch = () => {
        ref?.current?.blur();
    };

    useKeyPress(
        searchCommand?.key,
        searchCommand?.subkey,
        searchEnabled ? handleSearch : () => setShowDropdown(true),
        isOverlay,
        overlayName
    );

    useKeyPress("A", ["altKey"], handleAddUser, true, overlayName)


    return (
        <StyledReactSearchAutocomplete>
            <StyledSearchInput
                type="text"
                ref={ref}
                value={searchString}
                onChange={(e) => searchEnabled ? handleSearchString(e) : null}
                placeholder={placeholder}
                onClick={() => !searchEnabled && setShowDropdown(true)}
                autoFocus={autoFocus}
                onKeyDown={(event) => handleSelectedItem(event)}
            />
            {rightIcon ? <IconWrapper style={{opacity: "0.6"}}>{rightIcon}</IconWrapper> : <IconWrapper>{icon}</IconWrapper>}
            <RightIconWrapper>
              {dropDown ? <SearchIcon /> : <DownIcon/> }  
                <ExtraTextWrapper>{searchCommand.text}</ExtraTextWrapper>
            </RightIconWrapper>
            {
                (searchString || showDropdown ) &&
                <ResultsWrapper>
                    {results?.length > 0 && results.slice(0, maxResults).map((result, index) => (
                        <li
                            className={highlightedItem === index ? 'selected' : ''}
                            onClick={(e) => handleSelectedItem(e, index)}
                        >
                            <div className="ellipsis">
                                {searchEnabled ?
                                    <span> {highlightMatchingChars(searchString, result.name)}</span>
                                    : 
                                    <span>{result.name}</span>
                                }
                                {result.email && <span style={{ opacity: "0.6" }}> {`(${result.email})`}</span>}
                            </div>
                        </li>
                    ))}
                    
                    {enableAddButton && 
                        <>
                            <span style={{ paddingLeft: "13px", fontWeight: "bold", textTransform: "capitalize" }} onClick={() => handleAddUser()}>+ Add {searchString}</span>
                            <ExtraTextWrapper>[Alt+A]</ExtraTextWrapper>
                        </>
                    }
                </ResultsWrapper>
            }
        </StyledReactSearchAutocomplete >

    )
}


const ResultsWrapper = ({ children }) => {
    return (
        <StyledResults>
            <ul>{children}</ul>
        </StyledResults>
    )
}


export default SearchAutocomplete;