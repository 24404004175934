import styled from "styled-components";

export const DashboardPageWrapper = styled.div `
  display: flex;
  width: 100%;
  min-height: 100%;
  justify-content: space-between;
`;

export const ClientListWrapper = styled.div `
  display: inline-flex;
  height: 550px;
  padding: 20px 24px;
  flex-direction: column;
  align-items: center;
  width: calc(70% - 48px);
`;

export const AuditListWrapper = styled.div `
  display: inline-flex;
  height: 550px;
  padding: 20px 24px;
  flex-direction: column;
  align-items: center;
  width: calc(100%);
`;

export const GeneratedDocListWrapper = styled.div `
  display: flex;
  width: calc(30% - 36px);
  justify-content: center;
  padding-right: 24px;
`;


export const FullWidthWrapper = styled.div `
  display: inline-flex;
  height: 550px;
  padding: 20px 24px;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;