import React, { useEffect, useState } from "react";
import {
  ModalWrapper,
  HeaderWrapper,
  HeadTitle,
  HeadRight,
  Title,
  ExtraWrapper,
  ErrorWrapper,
} from "./AddProjectModal.styled";
import Button from "../../../components/Button/Button";
import InputField from "../../../components/InputField/InputField";
import { useDispatch } from "react-redux";
import DropDownNew from "../../../components/Dropdown/Dropdown";
import CompanyBadgeIcon from "../../../assets/svgs/CompanyBadgeIcon";
import CompanyAddressIcon from "../../../assets/svgs/CompanyAddressIcon";
import useKeyPress from "../../../hooks/useKeyPress";
import speechFormat from "../../../helpers/speechFormat";
import DateSelector from "../../../components/DateSelector/DateSelector";
import EntitlementIcon from "../../../assets/svgs/EntitlementIcon";
import { createProject } from "../../../apis/ApiServerRequests";
import { useSelector } from "react-redux";
import SearchAutocomplete from "../../../components/AutoCompleteSearch/AutoCompleteSearch";
import Autocomplete from "../../../components/Autocomplete/Autocomplete";
import { incrProjectCount } from "../../../store/slices/ProjectSlice";
// import SearchIcon from "../../assets/svgs/SearchIcon";

const AddProjectModal = ({ onClose, onSuccess, overlayName }) => {
  const dispatch = useDispatch();
  const [pharmaName, setPharmaName] = useState("");
  const [projectName, setProjectName] = useState("");
  const [submissionDate, setSubmissionDate] = useState(new Date());
  const [submissionType, setSubmissionType] = useState("");
  const [loading, setLoading] = useState(false);
  // const [errorMessage, setErrorMessage] = useState("");
  const client_list = useSelector((state) => state?.client?.client_list || []);
  const [pharmaCompanyList, setPharmaCompanyList] = useState([])


  useEffect(() => {
    if (client_list) {
      setPharmaCompanyList(client_list.map((item, index) => ({id: index, name: item["pharmaName"]})))
    }

  }, [client_list])

  const handleInputChange = (e, callback) => {
    callback(e.target.value);
  };

  const handleValidation = () => {
    let valid = true;

    if (!pharmaName) {
      valid = false;
    }

    if (!projectName) {
      valid = false;
    }

    if (!submissionType) {
      valid = false;
    }

    return valid;
  };



  const handleAddProject = () => {
    if (!handleValidation()) return;

    let data = {
      project_name: projectName,
      company_name: pharmaName,
      due_date: submissionDate,
      type: submissionType
    };
    createProject(data)
      .then(() => {
        setLoading(true);
        dispatch(incrProjectCount(1));
        onSuccess({ loading: true });
      })
      .finally(() => {
        setLoading(false);
        onSuccess({ loading: false });
      })
  };


  useKeyPress("Escape", [], onClose, true, overlayName, true);
 
  useKeyPress("Enter", [], handleAddProject, true, overlayName, true);

  return (
    <ModalWrapper>
      <HeaderWrapper>
        <HeadTitle>{"PROJECT TIMELINE CREATION"}</HeadTitle>
        <HeadRight>
          <Button
            type={"ghost"}
            text={"Cancel"}
            onClick={onClose}
            accessKeyText={"[Esc]"}
            data-speech={speechFormat({
              key: "Escape",
              action: "close",
              item: "Project Creation Dialog",
            })}
          />
          <Button
            type={"secondary"}
            text={loading ? "Saving..." : "Create"}
            onClick={handleAddProject}
            accessKeyText={"[Enter]"}
            disabled={!handleValidation() || loading}
            data-speech={speechFormat({
              key: "Enter",
              action: "create",
              item: "New Project",
            })}
          />
        </HeadRight>
      </HeaderWrapper>
      <div
        style={{
          height: "100%",
          width: "100%",
          overflowY: "scroll",
          overflowX: "hidden",
          paddingBottom: "100px",
        }}
      >
        <InputField
          focus={true}
          label={"Enter Project Name"}
          labelStyle={{
            position: "absolute",
            padding: "4px 50px",
          }}
          inputStyle={{ paddingBottom: "10px", height: "34px" }}
          width={"584"}
          type={"text"}
          value={projectName}
          onChangefunction={(e) => handleInputChange(e, setProjectName)}
          icon={CompanyBadgeIcon()}
        />
        <ExtraWrapper>
          <Title>Company Name</Title>
          {/* <InputField
            width={"584"}
            labelStyle={{
              position: "absolute",
              padding: "4px 50px",
            }}
            label={`Select the Company`}
            value={pharmaName}
            type={"text"}
            inputStyle={{ paddingBottom: "10px", height: "34px" }}
            onChangefunction={(e) => handleInputChange(e, setPharmaName)}
            icon={CompanyAddressIcon()}
          /> */}
          <div style={{ height: "52px" }}>
            <Autocomplete
              items={pharmaCompanyList}
              placeholder={"Search Company to Add"}
              onSelected={setPharmaName}
            />
          </div>
        </ExtraWrapper>
        <ExtraWrapper>
        <Title>Select Submission Date</Title>
            <DateSelector setValue={setSubmissionDate} value={submissionDate}/>
        </ExtraWrapper>
        <ExtraWrapper>
          <Title>Select Submission Type</Title>
          <div style={{ width: 584, marginBottom: "20px" }}>
          <DropDownNew
            width={"584"}
            label={`Select submission type`}
            labelStyle={{
              position: "absolute",
              padding: "4px 50px",
            }}
            options={["IND"]}
            defaultText={`IND`}
            onChangefunction={(value) =>
              setSubmissionType(value)
            }
            icon={EntitlementIcon()}
          />
          </div>
        </ExtraWrapper>
      </div>
    </ModalWrapper>
  );
};

export default AddProjectModal;
