import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  SideDrawerWrapper,
  TopNavWrapper,
  TopNavText,
  TopNavButton,
  TopWrapper,
  TableWrapper,
  TableHeading,
  HeadingCell,
  TableRows,
  TableDataRow,
  DataCell,
  ClientDetailsWrapper,
  RowWrapper,
  BottomNavWrapper,
  HeadingWrapper,
  DetailsWrapper,
} from "./TaskDetailSideDrawer.styled";
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import Button from "../../../components/Button/Button";
import { deleteTask } from "../../../apis/ApiServerRequests";
import useKeyPress from "../../../hooks/useKeyPress";
import InfoTextarea from "../../../components/InfoTextarea/InfoTextarea";
import SkeletonLoader from "../../../components/SkeletonLoader/SkeletonLoader";
import EditIcon from "../../../assets/svgs/EditIcon";
import speechFormat from "../../../helpers/speechFormat";
import DocumentIcon from "../../../assets/svgs/DocumentIcon";
import CalenderIcon from "../../../assets/svgs/CalenderIcon";
import DeleteIcon from "../../../assets/svgs/DeleteIcon";
import { useDispatch } from "react-redux";
import { incrTaskCount } from "../../../store/slices/TaskSlice";
import { formattedDate } from "../../../helpers/formatDate";

const TaskDetailSideDrawer = ({ isOpen, handleOpen, taskDetails }) => {

  const dispatch = useDispatch()

  const overlayName = "TaskSideDrawer"

  const handleEdit = () => {
    handleOpen({ edit: true });
  }

  const handleDelete = () => {
    deleteTask(taskDetails.task_id)
    .then(() => {
      dispatch(incrTaskCount(1))
      handleOpen()
    })
  }

  useKeyPress("Escape", [], handleOpen, true, overlayName);
  useKeyPress("D", [], handleDelete, true, overlayName);

  return (
    isOpen && (
      <SideDrawer isOpen={isOpen} handleOpen={handleOpen} overlayName={overlayName}>
        <SideDrawerWrapper>
        <TopNavWrapper>
              <TopNavText>TASK DETAILS</TopNavText>
              <TopNavButton>
                <Button
                  text={"Done"}
                  accessKeyText={"[ESC]"}
                  type={"ghost"}
                  onClick={handleOpen}
                  buttonStyle={{ padding: "9px 0px" }}
                  data-speech={speechFormat({
                    key: "Escape",
                    action: "close",
                    item: "Drawer",
                  })}
                />
              </TopNavButton>
            </TopNavWrapper>
          <TopWrapper>
            <BottomNavWrapper onClick={() => handleDelete()}>
              Delete Task
              <Button
                icon={<DeleteIcon />}
                accessKeyText={"[ D ]"}
                type={"ghost"}
                buttonStyle={{ padding: "0px" }}
              />
            </BottomNavWrapper>

            <DetailsWrapper>
              <InfoTextarea
                width={"412"}
                labelStyle={{
                  position: "absolute",
                  padding: "8px 50px",
                }}
                label={`Task Name`}
                value={taskDetails.title}
                type={"text"}
                inputStyle={{ paddingBottom: "10px", paddingTop: "24px" }}
                icon={DocumentIcon()}
              />

              <RowWrapper>
                <InfoTextarea
                  width={"198"}
                  labelStyle={{
                    position: "absolute",
                    padding: "8px 20px",
                  }}
                  label={`Status`}
                  loading={false}
                  value={taskDetails?.status}
                  type={"text"}
                  inputStyle={{ 
                    paddingBottom: "10px", 
                    paddingTop: "24px", 
                    wordWrap: "break-word",
                    height: "auto" 
                  }}
                  iconStyle={{
                    alignItems: "initial",
                    marginTop: "10px",
                    height: "auto",
                  }}
                  // icon={BusinessIcon()}
                />
                <InfoTextarea
                  width={"198"}
                  labelStyle={{
                    position: "absolute",
                    padding: "8px 20px",
                  }}
                  label={`Health`}
                  loading={false}
                  value={taskDetails?.health || "On Track"}
                  type={"text"}
                  inputStyle={{ 
                    paddingBottom: "10px", 
                    paddingTop: "24px", 
                    wordWrap: "break-word",
                    height: "auto" 
                  }}
                  iconStyle={{
                    alignItems: "initial",
                    marginTop: "10px",
                    height: "auto",
                  }}
                  // icon={SettingIcon()}
                />
              </RowWrapper>
              <InfoTextarea
                  width={"198"}
                  labelStyle={{
                    position: "absolute",
                    padding: "8px 50px",
                  }}
                  label={`Due Date`}
                  loading={false}
                  value={formattedDate(taskDetails?.due_date)}
                  type={"text"}
                  inputStyle={{ 
                    paddingBottom: "10px", 
                    paddingTop: "24px", 
                    wordWrap: "break-word",
                    height: "auto" 
                  }}
                  iconStyle={{
                    alignItems: "initial",
                    marginTop: "10px",
                    height: "auto",
                  }}
                  icon={<CalenderIcon />}
                />
              <RowWrapper>

              </RowWrapper>

            </DetailsWrapper>
            <HeadingWrapper>
              <TopNavText>ASSIGNED TO</TopNavText>
            </HeadingWrapper>
            <TableWrapper>
              <TableHeading>
                <HeadingCell width={"30px"}>S.No.</HeadingCell>
                <HeadingCell width={"280px"}>User</HeadingCell>
              </TableHeading>
              <TableRows>
                {false ? (
                  <div style={{ width: "100%" }}>
                    <SkeletonLoader count={5} height={60} />
                  </div>
                ) : (
                    <TableDataRow
                      key={0}
                      index={0}
                      islast={true}
                    >
                      <DataCell width={"30px"}>1.</DataCell>
                      <DataCell width={"280px"}>{taskDetails.assigned_to}</DataCell>
                    </TableDataRow>
                  
                )}
              </TableRows>
            </TableWrapper>
          </TopWrapper>


        </SideDrawerWrapper>
      </SideDrawer>
    )
  );
};

TaskDetailSideDrawer.propTypes = {};

TaskDetailSideDrawer.defaultProps = {};

export default TaskDetailSideDrawer;
