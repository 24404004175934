import React, { useState, useEffect } from "react";
import {
  TableWrapper,
  TableHeading,
  HeadingCell,
  TableRows,
  PaginationContainer,
  ListFooterWrapper,
  FooterText,
  TableInfoText,
} from "./TaskListTable.styled";
import { BiSolidRightArrow, BiSolidDownArrow } from "react-icons/bi";
import { connect, useSelector } from "react-redux";
import Pagination from "../../../components/Pagination/Pagination";
import useKeyPress from "../../../hooks/useKeyPress";
import SkeletonLoader from "../../../components/SkeletonLoader/SkeletonLoader";
import NoSearchFound from "../../../components/NoSearchFound/NoSearchFound";
import Button from "../../../components/Button/Button";
import speechFormat from "../../../helpers/speechFormat";
import useSpeechSynthesis from "../../../hooks/useSpeechSynthesis";
import { useDispatch } from "react-redux";
import TaskTableRow from "../TaskTableRow/TaskTableRow";
import { useLocation } from "react-router-dom";
import { EditTask, getTaskList } from "../../../apis/ApiServerRequests";
import { useRef } from "react";
import { addTaskList } from "../../../store/slices/TaskSlice";

const TaskListTable = ({
  task_count,
  task_list,
  searchInput,
  setSearchInput,
  is_contrast
}) => {
  const { speak, speaking, cancelSpeaking } = useSpeechSynthesis(false);

  const perPage = 7 + Math.min(2, Math.ceil((window.innerHeight - 720) / 60));
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [loading, setLoading] = useState(true);
  const [reading, setReading] = useState(false);

  const [itemList, setItemList] = useState([]);
  const [taskList, setTaskList] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [expandedRows, setExpandedRows] = useState({});

  const dispatch = useDispatch();

  const location = useLocation();
  const { projectId } = location.state || {};

  const active_list = useSelector((state) => state?.a11y?.active_list);
  // const overlay = useSelector((state) => state?.a11y?.overlay);

  // useEffect(() => {
  //   setSelectedRow(null);
  // }, [overlay]);

  useEffect(() => {
    if (!speaking && reading) setReading(false);
  }, [speaking]);

  // useEffect(() => {
  //   if (selectedRow !== null) dispatch(setActiveList("list"));
  // }, [selectedRow]);

  const handleRowChange = (index, updatedRow) => {
    setItemList((prevList) => 
      prevList.map((item, i) => (i === index ? updatedRow : item))
    );
    EditTask(updatedRow.task_id, updatedRow)
    .then(({data}) => {
      console.log("data after edit ==>", data)
    })
    .catch((err) => {
      console.log("err in edit ==>", err)
    })
  }

  useEffect(() => {
    if (!active_list.includes("list")) setSelectedRow(null);
  }, [active_list]);

  useEffect(() => {
    if (task_count || projectId) {
      setLoading(true);
      let data = {
        query: searchInput?.length > 2 ? searchInput : "",
        projectId
      };
      getTaskList(data)
        .then(({ data }) => {
          setTaskList(data);
          dispatch(addTaskList(data));
          setTotalPages(Math.ceil(data?.length ? data?.length / perPage : 0));
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      let data = task_list;
      setTaskList(data);
      setTotalPages(Math.ceil(data?.length ? data?.length / perPage : 0));
      setLoading(false);
    }
  }, [task_count, projectId, searchInput]);

  useEffect(() => {
    if (taskList.length) {
      setItemList(taskList);
      setTotalPages(
        Math.ceil(taskList?.length ? taskList?.length / perPage : 0)
      );
    }
  }, [searchInput, currentPage, taskList]);

  const handleSubtaskToggle = (key, level, row) => {
    setExpandedRows((prev) => {
      const newExpandedRows = { ...prev, [key]: !prev[key] };
  
      if (!newExpandedRows[key]) {
        collapseChildren(row.subtasks, level + 1, key, newExpandedRows);
      }
  
      return newExpandedRows;
    });
    setSelectedRow(key);
  };

  // 🛠 Helper function to collapse children when parent collapses
  const collapseChildren = (subtasks, level, parentKey, state) => {
    if (!subtasks) return;
    subtasks.forEach((subRow, subIndex) => {
      const childKey = `${parentKey}-${subIndex}`;
      delete state[childKey]; // Remove child from expanded state
      collapseChildren(subRow.subtasks, level + 1, childKey, state); // Recursively collapse deeper levels
    });
  };


  useEffect(() => {
    setCurrentPage(1);
  }, [searchInput]);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };


  const getVisibleRows = (items, expandedRows, level = 1, parentKey = "") => {
    let result = [];
  
    items.forEach((row, index) => {
      const key = parentKey ? `${parentKey}-${index}-${level}` : `${index}-${level}`;
      result.push({ key, row });
  
      if (expandedRows[key] && row.subtasks) {
        result = result.concat(getVisibleRows(row.subtasks, expandedRows, level + 1, key));
      }
    });
  
    return result;
  };


  const handleArrowUP = () => {
    if (!active_list.includes("list")) return;
  
    const visibleRows = getVisibleRows(itemList, expandedRows);
    const currentIndex = visibleRows.findIndex(row => row.key === selectedRow);
  
    if (currentIndex > 0) {
      setSelectedRow(visibleRows[currentIndex - 1].key);
    }
  };

  const handleArrowDown = () => {
    if (!active_list.includes("list")) return;

    // Convert expandedRows object to an array of visible rows
    const visibleRows = getVisibleRows(itemList, expandedRows);
    const currentIndex = visibleRows.findIndex(row => row.key === selectedRow);
    if (currentIndex < visibleRows.length - 1) {
      setSelectedRow(visibleRows[currentIndex + 1].key);
    }
  };

  const handleListRead = () => {
    if (reading) {
      cancelSpeaking();
      setReading(false);
      return;
    }
    let speech = "";
    itemList.forEach(({ pharmaName }, i) => {
      speech += speechFormat({
        key: `Alt + ${i + 1}`,
        action: "select",
        item: pharmaName,
      });
      speech += ". ";
    });
    setReading(true);
    speak(speech);
  };


  const handleEnter = () => {
    const visibleRows = getVisibleRows(itemList, expandedRows);
    const selectedItem = visibleRows.find(row => row.key === selectedRow);

    if (selectedItem) {
      handleSubtaskToggle(selectedItem.key, selectedItem.row.level, selectedItem.row);
    }
  }

  const handleCollapseAll = () => {
    setExpandedRows({}); // Reset all expanded rows
  };

  useKeyPress("C", [], handleListRead);
  useKeyPress("ArrowUp", [], handleArrowUP);
  useKeyPress("ArrowDown", [], handleArrowDown);
  useKeyPress("Enter", [], handleEnter);
  useKeyPress("Escape", [], handleCollapseAll);
  useKeyPress("K", ["altKey"], () => setSearchInput(""));



  const onSelectTopLevelRow = (index) => {
    if (itemList[index]) {
      setSelectedRow(`${index}-1`); // Ensure it only selects top-level tasks
    }
  };
  
  useKeyPress("1", ["altKey"], () => onSelectTopLevelRow(0));
  useKeyPress("2", ["altKey"], () => onSelectTopLevelRow(1));
  useKeyPress("3", ["altKey"], () => onSelectTopLevelRow(2));
  useKeyPress("4", ["altKey"], () => onSelectTopLevelRow(3));
  useKeyPress("5", ["altKey"], () => onSelectTopLevelRow(4));
  useKeyPress("6", ["altKey"], () => onSelectTopLevelRow(5));
  useKeyPress("7", ["altKey"], () => onSelectTopLevelRow(6));
  useKeyPress("8", ["altKey"], () => onSelectTopLevelRow(7));
  useKeyPress("9", ["altKey"], () => onSelectTopLevelRow(8));

  return (
    <>
      <TableWrapper>
        <TableHeading>
          <HeadingCell is_contrast={is_contrast} width={"10%"}>
            Task ID
          </HeadingCell>
          <HeadingCell is_contrast={is_contrast} width={"30%"}>
            Task <span>*</span>
          </HeadingCell>
          <HeadingCell is_contrast={is_contrast} width={"15%"}>
            Due Date 
          </HeadingCell>
          <HeadingCell is_contrast={is_contrast} width={"20%"}>
            Assigned To 
          </HeadingCell>
          <HeadingCell is_contrast={is_contrast} width={"10%"}>
            Status
          </HeadingCell>
          <HeadingCell is_contrast={is_contrast} width={"10%"} >
            Health
          </HeadingCell>
        </TableHeading>
        <TableRows>
          {loading ? (
            <div style={{ width: "100%" }}>
              <SkeletonLoader count={perPage} height={60} />
            </div>
          ) : (
            <>
              {searchInput && itemList.length === 0 ? (
                <NoSearchFound
                  height={"336px"}
                  resetSearch={() => setSearchInput("")}
                />
              ) : (
                itemList.map((row, index) => (
                  <TaskRow 
                    key={index} 
                    row={row} 
                    index={index} 
                    level={1} 
                    expandedRows={expandedRows} 
                    handleRowChange={handleRowChange}
                    handleSubtaskToggle={handleSubtaskToggle} 
                    selectedRow={selectedRow} 
                  />
                ))
              )}
            </>
          )}
        </TableRows>
      </TableWrapper>
      <TableInfoText>
        <span>*</span>Shortcuts for the selected row are{" "}
        <span>Show Details [1]  | Collapse List [Esc]  </span>{" "}
      </TableInfoText>
      <ListFooterWrapper>
        <FooterText>
          <Button
            type="ghost"
            text={reading ? "Cancel Read List" : "Read List"}
            accessKeyText="[ C ]"
            buttonStyle={{ padding: "4px 0px" }}
            data-speech={speechFormat({
              key: "C",
              action: reading ? "Cancel Reading of" : "read",
              item: "Task List",
            })}
            onClick={handleListRead}
          />
        </FooterText>
        <PaginationContainer>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onFirstPage={() => setCurrentPage(1)}
            onLastPage={() => setCurrentPage(totalPages)}
            onPrev={handlePrevPage}
            onNext={handleNextPage}
            firstAccessKey={{
              text: "[Ctrl + ;]",
              key: ";",
              subkey: ["ctrlKey"],
              speech: speechFormat({
                key: "Control + Semicolon",
                action: "goto",
                item: "First page of Task list",
              }),
            }}
            prevAccessKey={{
              text: "[Ctrl + ,]",
              key: ",",
              subkey: ["ctrlKey"],
              speech: speechFormat({
                key: "Control + comma",
                action: "goto",
                item: "Previous page of Task list",
              }),
            }}
            nextAccessKey={{
              text: "[Ctrl + .]",
              key: ".",
              subkey: ["ctrlKey"],
              speech: speechFormat({
                key: "Control + fullstop",
                action: "goto",
                item: "Next page of Task list",
              }),
            }}
            lastAccesskey={{
              text: "[Ctrl + ']",
              key: "'",
              subkey: ["ctrlKey"],
              speech: speechFormat({
                key: "Control + single quote",
                action: "goto",
                item: "Last page of Task list",
              }),
            }}
          />
        </PaginationContainer>
      </ListFooterWrapper>
    </>
  );
};


const TaskRow = ({ row, index, parentKey = "", level, expandedRows, handleSubtaskToggle, selectedRow, handleRowChange }) => {
  const key = parentKey ? `${parentKey}-${index}-${level}` : `${index}-${level}`;
  const isExpanded = expandedRows[key] || false; // Default to false

  console.log(key, selectedRow);

   // Create a ref for the row
  const rowRef = useRef(null);

   // Scroll into view when the row is selected
  useEffect(() => {
    if (selectedRow === key && rowRef.current) {
      rowRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [selectedRow]);

  return (
    <div ref={selectedRow === key ? rowRef : null} style={{ width: "inherit" }}>
      <TaskTableRow
        key={key}
        index={index}
        row={row}
        showSubTask={`${(level - 1) * 30}px`} // Dynamic indentation
        onRowChange={(index, updatedRow) => handleRowChange(index, updatedRow)}
        isLast={false}
        onSelectRow={() => handleSubtaskToggle(key, level, row)}
        expandableIcon={
          row.subtasks?.length
            ? isExpanded
              ? <div style={{paddingTop: "2px"}}><BiSolidDownArrow size={12} /></div>
              : <BiSolidRightArrow size={12} />
            : null
        }
        isSelected={selectedRow === key}
        showId={level === 1}
      />

      {/* Render Subtasks Recursively */}
      {isExpanded && row.subtasks?.map((subRow, subIndex) => (
        <TaskRow
          key={`${key}-${subIndex}`} // Unique key combining parentKey and subIndex
          row={subRow}
          index={subIndex}
          parentKey={key} // Pass parent key for unique identification
          level={level + 1}
          expandedRows={expandedRows}
          handleSubtaskToggle={handleSubtaskToggle}
          selectedRow={selectedRow}
          handleRowChange={handleRowChange}
        />
      ))}
    </div>
  );
};

TaskListTable.propTypes = {};

TaskListTable.defaultProps = {};

const mapStateToProps = (state) => {
  return {
    task_count: state?.task?.task_count || 0,
    task_list: state?.task?.task_list || 0,
    is_contrast: state?.a11y?.high_contrast,
  };
};

export default connect(mapStateToProps)(TaskListTable);
