import styled from "styled-components";

export const TableDataRow = styled.div`
  display: flex;
  padding: 6px 12px;
  height: 48px;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  width: calc(100% - 24px);
`;

export const TableRowWrapper = styled.div`
  background: ${({ theme, index, isSelected }) =>
    isSelected
      ? theme.color_theme.selectedBackgroundColor
      : index % 2 === 0
        ? theme.color_theme.tableStripColorLight
        : theme.color_theme.tableStripColorDark};
  width: 100%;
  display: flex;
  border-bottom-right-radius: ${({ islast }) => (islast ? "8px" : "0px")};
  border-bottom-left-radius: ${({ islast }) => (islast ? "8px" : "0px")};
  color: ${(props) => props.theme.color_theme.selectedTheme === "DC_DM_THEME" && props.isSelected ? "#000" : ""};
`;

export const DataCell = styled.div`
  min-width: ${({ width }) => `calc(${width} - 12px);`};
  max-width: ${({ width }) => `${width}`};
  display: ${({ theme, thres }) =>
    thres && theme.window_width < thres ? "none" : "flex"};
  font-family: Segoe UI;
  font-size: ${(props) => props.theme.font_delta + 14}px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const DataCellAction = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 15px;
  svg {
    fill: ${(props) => props.theme.color_theme.selectedTheme === "DC_DM_THEME" && props.isSelected ? "#000" : props.theme.color_theme.primaryColor};
    cursor: pointer;
  }

  span {
    display: flex;
    align-items: center;
    gap: 5px;
    color: ${(props) => props.theme.color_theme.selectedTheme === "DC_DM_THEME" && props.isSelected ? "#000" : props.theme.color_theme.color};
    font-family: Segoe UI;
    font-size: ${(props) => props.theme.font_delta + 12}px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    margin-left: 6px;
  }

  img {
    color: ${(props) => props.theme.color_theme.color};
  }
`;

export const TextWapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: ${(props) => props.theme.color_theme.color};
  margin-right: 10px;
  font-family: Segoe UI;
  font-size: ${(props) => props.theme.font_delta + 12}px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
`;


export const NumberWrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  // text-decoration: underline;
  color: ${(props) => props.theme.color_theme.selectedTheme === "DC_DM_THEME" && props.isSelected ? "#000" : props.theme.color_theme.primaryColor};
  padding-left: ${(props) => props.showSubTask};
  gap: 8px;
  &:hover {
    text-decoration: underline;
  }
`;

export const CardSpan = styled.span`
  display: flex;
  flexDirection: row;
  alignItems: center;
  gap: 10px;
  cursor: pointer;
  fill: ${(props) => props.theme.color_theme.selectedTheme === "DC_DM_THEME" && props.isSelected ? "#000" : props.theme.color_theme.primaryColor};
`;


export const LabelCard = styled.div`
  border: ${(props) => `1px solid ${props.theme.color_theme.inputBorderColor}`};
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  z-index: 2;
  width: 240px;
  position: absolute;
  padding: 10px 20px;
  background:  ${(props) => `${props.theme.color_theme.bodyColor}`};
  flex-direction: row; /* Align children (spans) in a row */
  flex-wrap: wrap; /* Allow wrapping if spans exceed the width */
  gap: 12px; /* Add gap between spans */

  & span {
    display: inline-block;
    padding: 4px 12px; /* Adjust for desired capsule size */
    border-radius: 20px; /* Makes it look like a capsule */
    // background-color: ${(props) => props.theme.color_theme.primaryColor};
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: ${(props) => props.theme.color_theme.textColor};
    font-size: ${(props) => props.theme.font_delta}px;
    font-weight: 500;
    text-align: center;
    white-space: nowrap; /* Ensures single-line text */
    margin: 4px 0; /* Space between multiple spans */
    cursor: pointer;

    &:hover {
      background-color: #2E9866;
    }
  }
`;


export const AssignUserModal = styled.div`
  border: ${(props) => `1px solid ${props.theme.color_theme.inputBorderColor}`};
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  z-index: 2;
  width: 440px;
  position: absolute;
  padding: 10px 20px;
  background:  ${(props) => `${props.theme.color_theme.bodyColor}`};
  flex-direction: row; /* Align children (spans) in a row */
  flex-wrap: wrap; /* Allow wrapping if spans exceed the width */
  gap: 12px; /* Add gap between spans */
`;

export const UserDetailWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 12px;
  padding-top: 15px;
  padding-bottom: ${({ bottomPadding }) =>
    bottomPadding ? `${bottomPadding}px` : "15px"};
  border-bottom: 1px solid #f2f2f2;
`;