import React, { useState, useEffect } from "react";
import {
  TableWrapper,
  TableHeading,
  HeadingCell,
  TableRows,
  PaginationContainer,
  ListFooterWrapper,
  FooterText,
  TableInfoText,
} from "./ProjectListTable.styled";
import { connect, useSelector } from "react-redux";
import Pagination from "../../../components/Pagination/Pagination";
import useKeyPress from "../../../hooks/useKeyPress";
import SkeletonLoader from "../../../components/SkeletonLoader/SkeletonLoader";
import NoSearchFound from "../../../components/NoSearchFound/NoSearchFound";
import Button from "../../../components/Button/Button";
import speechFormat from "../../../helpers/speechFormat";
import useSpeechSynthesis from "../../../hooks/useSpeechSynthesis";
import { useDispatch } from "react-redux";
import ProjectTableRow from "../ProjectTableRow/ProjectTableRow";
import { setActiveList } from "../../../store/slices/A11ySlice";
import { getProjectList } from "../../../apis/ApiServerRequests";

const ProjectListTable = ({
  project_count,
  project_list,
  searchInput,
  setSearchInput,
  is_contrast,
}) => {
  const { speak, speaking, cancelSpeaking } = useSpeechSynthesis(false);
  const dispatch = useDispatch();
  const active_list = useSelector((state) => state?.a11y?.active_list);

  const [perPage, setPerPage] = useState(
    7 + Math.min(2, Math.ceil((window.innerHeight - 720) / 60))
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [loading, setLoading] = useState(true);
  const [reading, setReading] = useState(false);

  const [itemList, setItemList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    const updatePerPage = () => {
      setPerPage(7 + Math.min(2, Math.ceil((window.innerHeight - 720) / 60)));
    };

    window.addEventListener("resize", updatePerPage);
    return () => window.removeEventListener("resize", updatePerPage);
  }, []);

  useEffect(() => {
    if (!speaking && reading) setReading(false);
  }, [speaking]);

  useEffect(() => {
    if (selectedRow !== null) dispatch(setActiveList("project_list"));
  }, [selectedRow]);

  useEffect(() => {
    if (active_list !== "project_list") setSelectedRow(null);
  }, [active_list]);

  useEffect(() => {
    let data = {
      query: searchInput?.length > 3 ? searchInput : "",
      skip: 0,
      limit: 100,
    };
    getProjectList(data).then(({ data }) => {
      setProjectList(data);
      setTotalPages(Math.ceil(data?.length / perPage));
      setLoading(false);
    });
  }, [searchInput, project_count, project_list, perPage]);

  useEffect(() => {
    const startIndex = (currentPage - 1) * perPage;
    const endIndex = startIndex + perPage;
    setItemList(projectList.slice(startIndex, endIndex));
    setTotalPages(Math.ceil(projectList.length / perPage));
  }, [searchInput, currentPage, projectList, perPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchInput]);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleArrowUP = () => {
    if (active_list === "list")
      setSelectedRow(Math.max(0, selectedRow - 1));
  };

  const handleArrowDown = () => {
    let l = itemList.length;
    if (active_list === "list")
      setSelectedRow(Math.min(l - 1, selectedRow + 1));
  };

  const handleListRead = () => {
    if (reading) {
      cancelSpeaking();
      setReading(false);
      return;
    }
    let speech = "";
    itemList.forEach(({ pharmaName }, i) => {
      speech += speechFormat({
        key: `Alt + ${i + 1}`,
        action: "select",
        item: pharmaName,
      });
      speech += ". ";
    });
    setReading(true);
    speak(speech);
  };

  useKeyPress("C", [], handleListRead);
  useKeyPress("ArrowUp", [], handleArrowUP);
  useKeyPress("ArrowDown", [], handleArrowDown);
  useKeyPress("K", ["altKey"], () => setSearchInput(""));

  return (
    <>
      <TableWrapper>
        <TableHeading>
          <HeadingCell is_contrast={is_contrast} width={"10%"}>
            Project ID
          </HeadingCell>
          <HeadingCell is_contrast={is_contrast} width={"20%"}>
            Project Name <span>*</span>
          </HeadingCell>
          <HeadingCell is_contrast={is_contrast} width={"20%"}>
            Company Name 
          </HeadingCell>
          <HeadingCell is_contrast={is_contrast} width={"10%"}>
            Type 
          </HeadingCell>
          <HeadingCell is_contrast={is_contrast} width={"10%"}>
            Health
          </HeadingCell>
          <HeadingCell is_contrast={is_contrast} width={"10%"} >
            Due Date
          </HeadingCell>

          <HeadingCell is_contrast={is_contrast} width={"20%"} >
            Progress
          </HeadingCell>
        </TableHeading>
        <TableRows>
          {loading ? (
            <div style={{ width: "100%" }}>
              <SkeletonLoader count={perPage} height={60} />
            </div>
          ) : (
            <>
              {searchInput && itemList.length === 0 ? (
                <NoSearchFound
                  height={"336px"}
                  resetSearch={() => setSearchInput("")}
                />
              ) : (
                itemList.map((row, index, array) => (
                  <ProjectTableRow
                    key={index}
                    index={index}
                    row={row}
                    islast={index === array.length - 1}
                    onSelectRow={setSelectedRow}
                    isSelected={selectedRow === index}
                  />
                ))
              )}
            </>
          )}
        </TableRows>
      </TableWrapper>
      <TableInfoText>
        <span>*</span>Shortcuts for the selected row are{" "}
        <span>Show Details [1]</span>{" "}
      </TableInfoText>
      <ListFooterWrapper>
        <FooterText>
          <Button
            type="ghost"
            text={reading ? "Cancel Read List" : "Read List"}
            accessKeyText="[ C ]"
            buttonStyle={{ padding: "4px 0px" }}
            data-speech={speechFormat({
              key: "C",
              action: reading ? "Cancel Reading of" : "read",
              item: "Project List",
            })}
            onClick={handleListRead}
          />
        </FooterText>
        <PaginationContainer>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onFirstPage={() => setCurrentPage(1)}
            onLastPage={() => setCurrentPage(totalPages)}
            onPrev={handlePrevPage}
            onNext={handleNextPage}
            firstAccessKey={{
              text: "[Ctrl + ;]",
              key: ";",
              subkey: ["ctrlKey"],
              speech: speechFormat({
                key: "Control + Semicolon",
                action: "goto",
                item: "First page of Project list",
              }),
            }}
            prevAccessKey={{
              text: "[Ctrl + ,]",
              key: ",",
              subkey: ["ctrlKey"],
              speech: speechFormat({
                key: "Control + comma",
                action: "goto",
                item: "Previous page of Project list",
              }),
            }}
            nextAccessKey={{
              text: "[Ctrl + .]",
              key: ".",
              subkey: ["ctrlKey"],
              speech: speechFormat({
                key: "Control + fullstop",
                action: "goto",
                item: "Next page of Project list",
              }),
            }}
            lastAccesskey={{
              text: "[Ctrl + ']",
              key: "'",
              subkey: ["ctrlKey"],
              speech: speechFormat({
                key: "Control + single quote",
                action: "goto",
                item: "Last page of Project list",
              }),
            }}
          />
        </PaginationContainer>
      </ListFooterWrapper>
    </>
  );
};

ProjectListTable.propTypes = {};

ProjectListTable.defaultProps = {};

const mapStateToProps = (state) => {
  return {
    project_count: state?.project?.project_count || 0,
    project_list: state?.project?.project_list || 0,
    is_contrast: state?.a11y?.high_contrast,
  };
};

export default connect(mapStateToProps)(ProjectListTable);
