import React, { useEffect, useState } from "react";
import {
  TableDataRow,
  DataCell,
  TableRowWrapper,
  NumberWrapper,
  DataCellAction,
} from "./ProjectTableRow.styled";
import useKeyPress from "../../../hooks/useKeyPress";
import speechFormat from "../../../helpers/speechFormat";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import { useNavigate } from "react-router-dom";
import { formattedDate } from "../../../helpers/formatDate";

const ProjectTableRow = ({
  row,
  index,
  onSelectRow,
  isSelected,
  islast,
  currentTab,
}) => {
    
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleRowClick = () => {
    onSelectRow(index);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  useKeyPress("1", ["altKey"], () => onSelectRow(0));
  useKeyPress("2", ["altKey"], () => onSelectRow(1));
  useKeyPress("3", ["altKey"], () => onSelectRow(2));
  useKeyPress("4", ["altKey"], () => onSelectRow(3));
  useKeyPress("5", ["altKey"], () => onSelectRow(4));
  useKeyPress("6", ["altKey"], () => onSelectRow(5));
  useKeyPress("7", ["altKey"], () => onSelectRow(6));
  useKeyPress("8", ["altKey"], () => onSelectRow(7));
  useKeyPress("9", ["altKey"], () => onSelectRow(8));


  useKeyPress("1", [], () => isSelected && navigate(`/project/${row?.project_name}`, { state: { projectId: row.project_id} }));

  return (
    <>
        <TableRowWrapper isSelected={isSelected} index={index} islast={islast}>
          <TableDataRow
            key={index}
            onClick={handleRowClick}
            isSelected={isSelected}
          >
            <DataCell
              isSelected={isSelected}
              width={"10%"}
              style={{ justifyContent: "normal" }}
            >
              <div style={{ marginRight: "4px" }} 
              data-speech={speechFormat({
                key: `Alt + ${index + 1}`,
                action:"select",
                item: `project with the id ${index+1}`,
              })}
              tabIndex={0}>{index + 1}.</div>
              <span> [Alt + {index + 1}]</span>
            </DataCell>
            <DataCell 
              width={"20%"} 
              style={{ justifyContent: "normal", cursor: "pointer" }} 
              tabIndex={0} aria-label="project name" 
              onClick={() => navigate(`/project/${row.project_name}`, { state: { projectId: row.project_id} })}
            >
            <DataCellAction isSelected={isSelected} >
              <span style={{ opacity: "0.8" }}>[1]</span>
            </DataCellAction>
              <NumberWrapper isSelected={isSelected} tabIndex={0}>{row.project_name}</NumberWrapper>
            </DataCell>
            <DataCell width={"20%"} style={{ justifyContent: "normal" }} tabIndex={0} aria-label="company name">
              <span>{row.company_name}</span>
            </DataCell>
            <DataCell width={"10%"} style={{ justifyContent: "normal" }}>
              <span
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {row.type}
              </span>
            </DataCell>
            <DataCell width={"10%"} style={{ justifyContent: "normal" }}>
            <span
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "10px",
                }}
              >{row.health}</span>
            </DataCell>
            <DataCell width={"10%"} style={{ justifyContent: "normal" }}>
              <span
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {formattedDate(row.due_date)}
              </span>
            </DataCell>
            <DataCell width={"25%"} style={{ justifyContent: "normal" }} tabIndex={0} aria-label="progress">
              <span>
                <ProgressBar 
                    progress={{"mappings_processed": row.progress, "total_mappings": 100}} 
                    width="160px"
                />
              </span>
            </DataCell>
          </TableDataRow>
        </TableRowWrapper>
      
    </>
  );
};

export default ProjectTableRow;
